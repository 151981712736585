.trustedBySection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: clamp(5px, 1vw, 12px);
    box-sizing: border-box;
    .trustedBySectionImg {
        object-fit: contain;
        object-position: center;
        max-width: 100%;
        max-height: 100%;
    }
}

// @media screen and (max-width:600px) {
//     .trustedBySectionImg {
//         width: 90px;
//         height: 80px;
//     }
// }