@media screen and (max-width:1200px) {
    .joinUsSectionImg{
        max-height: 400px;
    }
}


@media screen and (max-width:1024px) {
    .joinUsSectionImg{
        max-height: 375px;
    }
}

@media screen and (max-width:768px) {
    .joinUsSectionImg{
        max-height: 350px;
    }
}
@media screen and (max-width:400px) {
    .joinUsSectionImg{
        max-height: 300px;
    }
}