@media screen and (min-width:1180px) and (max-width:1280px) {
    .experienceSectionContainer{
        margin-top: 150px;
    }
 }

 @media screen and (min-width:480px) and (max-width:910px) {
    .experienceSectionContainer{
        margin-top: 140px;
    }
 }