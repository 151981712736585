.preloadingScreenOpacityBack {
    animation: example 0.2s linear alternate;   
}
.preloadAnimationBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
.preloadText1,.preloadText2 {
    display: flex;
    align-items: center;
    justify-content: center;
    .preloadimage {
        max-height: 16vh;
        max-width: 80%;
        margin: 0;
    }
}
.preloadText1 {
    display: flex;
    align-items: center;
    justify-content: center;
    .preloadimage {
        max-height: 22vh;
        max-width: 90%;
        margin: 0;
    }
}
}


@keyframes example{
    from{opacity: 0;}
    to{opacity: 100;}
}


.preloadAnimationBox{
    justify-content: center;
}

.preloadText1{
   animation: anim1 1.5s ease-in-out normal;
   transform: translateX(200px);
   animation-delay: 1s;
}
.preloadText2{
   
    animation: anim2 1.5s ease-in-out normal;
    opacity: 0;
    animation-delay: 1s;
  
}

@keyframes anim1{
  
    25%{
        transform: translateX(-500px);
    }
    50%{
        transform: translateX(0px);
    }
    100%{
        transform: translateX(0px);
    }
}
@keyframes anim2{
    25%{
        opacity: 100;
        transform: translateX(500px);
    }
    50%{
        transform: translateX(0px);
        opacity: 75;
    }
    100%{
            opacity: 0;
    }
}

@media screen and (max-width:1400px) {
    @keyframes anim1{
  
        25%{
            transform: translateX(-380px);
        }
        50%{
            transform: translateX(0px);
        }
        100%{
            transform: translateX(0px);
        }
    }
    @keyframes anim2{
        25%{
            opacity: 100;
            transform: translateX(380px);
        }
        50%{
            transform: translateX(0px);
            opacity: 75;
        }
        100%{
                opacity: 0;
        }
    }
}
@media screen and (max-width:1300px) {
    @keyframes anim1{
  
        25%{
            transform: translateX(-310px);
        }
        50%{
            transform: translateX(0px);
        }
        100%{
            transform: translateX(0px);
        }
    }
    @keyframes anim2{
        25%{
            opacity: 100;
            transform: translateX(310px);
        }
        50%{
            transform: translateX(0px);
            opacity: 75;
        }
        100%{
                opacity: 0;
        }
    }
}

@media screen and (max-width:1200px) {
    @keyframes anim1{
  
        25%{
            transform: translateX(-240px);
        }
        50%{
            transform: translateX(0px);
        }
        100%{
            transform: translateX(0px);
        }
    }
    @keyframes anim2{
        25%{
            opacity: 100;
            transform: translateX(240px);
        }
        50%{
            transform: translateX(0px);
            opacity: 75;
        }
        100%{
                opacity: 0;
        }
    }
}

@media screen and (max-width:1100px) {
    @keyframes anim1{
  
        25%{
            transform: translateX(-170px);
        }
        50%{
            transform: translateX(0px);
        }
        100%{
            transform: translateX(0px);
        }
    }
    @keyframes anim2{
        25%{
            opacity: 100;
            transform: translateX(170px);
        }
        50%{
            transform: translateX(0px);
            opacity: 75;
        }
        100%{
                opacity: 0;
        }
    }
}

@media screen and (max-width:768px) {
    .preloadText1{
        transform: translateX(150px);
    }
    @keyframes anim1{
  
        25%{
            transform: translateX(-120px);
        }
        50%{
            transform: translateX(0px);
        }
        100%{
            transform: translateX(0px);
        }
    }
    @keyframes anim2{
        25%{
            opacity: 100;
            transform: translateX(120px);
        }
        50%{
            transform: translateX(0px);
            opacity: 75;
        }
        100%{
                opacity: 0;
        }
    }
}
@media screen and (max-width:600px) {
    .preloadText1{
        transform: translateX(150px);
    }
    @keyframes anim1{
  
        25%{
            transform: translateX(-80px);
        }
        50%{
            transform: translateX(0px);
        }
        100%{
            transform: translateX(0px);
        }
    }
    @keyframes anim2{
        25%{
            opacity: 100;
            transform: translateX(80px);
        }
        50%{
            transform: translateX(0px);
            opacity: 75;
        }
        100%{
                opacity: 0;
        }
    }
}
@media screen and (max-width:500px) {
    .preloadText1{
        transform: translateX(120px);
    }
    @keyframes anim1{
  
        25%{
            transform: translateX(-50px);
        }
        50%{
            transform: translateX(0px);
        }
        100%{
            transform: translateX(0px);
        }
    }
    @keyframes anim2{
        25%{
            opacity: 100;
            transform: translateX(50px);
        }
        50%{
            transform: translateX(0px);
            opacity: 75;
        }
        100%{
                opacity: 0;
        }
    }
}