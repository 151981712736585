.ourApproachBox1 {
    animation-name: animate1;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-direction:normal;
    animation-timing-function:ease-in-out;
 
}

@keyframes animate1 {
    0% {
        background: black;
        color: white;
    }
    25% { background: white; }
    50% { background: white; }
    75% { background: white; }
    100% { background: white; }
}

.ourApproachBox2 {

    animation-name: animate2;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-direction:normal;
    animation-timing-function:ease-in-out;
}

@keyframes animate2 {
    0% { background: white; }
    25% {
        background: black;
        color: white;
    }
    50% { background: white; }
    75% { background: white; }
    100% { background: white; }
}
.ourApproachBox3 {

    animation-name: animate3;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-direction:normal;
    animation-timing-function:ease-in-out;
    


}

@keyframes animate3 {
    0% { background: white; }
    25% {background: white;}
    50% { 
        background: black;
        color: white; }
    75% { background: white; }
    100% { background: white; }
}
.ourApproachBox4 {

    animation-name: animate4;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-direction:normal;
    animation-timing-function:ease-in-out;
    


}

@keyframes animate4 {
    0% { background: white; }
    25% {background: white;}
    50% { background: white; }
    75% { 
        background: black;
        color: white; }
    
    100% { background: white; }
}
.ourApproachBox5{

    animation-name: animate5;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-direction:normal;
    animation-timing-function:ease-in-out;
    
}

@keyframes animate5 {
    0% { background: white; }
    25% {background: white;}
    50% { background: white; }
    75% { background: white; }
    100% { 
        background: black;
        color: white; }
    
    
}