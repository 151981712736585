.scrollbarinvisible {
    *::-webkit-scrollbar {
        display: none !important;
    }
}

.floorplanmaincontainer {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden !important;
    z-index: 10;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: white;
}

.floorplanViewModalBox {
    animation: example 0.5s linear alternate;
    width: 100%;
    height: 100vh;
    padding: 3vh 3vw 5vh 3vw !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: space-evenly !important;
    // overflow: hidden !important;
}

.floorplancontainer {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    // overflow: hidden !important;
    img {
        position: relative !important;
        width: 100% !important;
        max-height: 75vh !important;
        object-fit: contain;
        aspect-ratio: 1 !important;
    }
}



@media screen and (max-width:768px) {
    .floorplanViewModalBox {
        padding: 3vh 0 5vh 0 !important;
        h3, p {
            padding: 0 3vw;
        }
    }
    .floorplancontainer {
        img {
            max-height: 75vh !important;
        }
    }
}

@keyframes example{
    from{opacity: 0;}
    to{opacity: 100;}
}

.projectDetailsGalleryImg{
    max-height: 600px;
    width: 100%;
    object-fit: contain !important;
}

@media screen and (max-width:768px) {
    .projectDetailsGalleryImg{
        max-height: 400px;
    }
}
.allProjectsSectionOpacityContainer img {
    object-fit: cover !important;
    object-position: center;
}