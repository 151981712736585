.whoAreWeSectionImg{
    height: 600px;
    object-fit: contain;
}

@media screen and (max-width:1300px) {
    .whoAreWeSectionImg{
        max-height: 400px;
    }
}
@media screen and (max-width:1024px) {
    .whoAreWeSectionImg{
        max-height: 330px;
    }
}
@media screen and (max-width:768px) {
    .whoAreWeSectionImg{
        max-height: 280px;
    }
}
@media screen and (max-width:600px) {
    .whoAreWeSectionImg{
        max-height: 210px;
    }
}
@media screen and (max-width:450px) {
    .whoAreWeSectionImg{
        max-height: 155px;
    }
}