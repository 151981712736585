.ourTeamSectionModalImg{
  
    width: 600px;
}

.ourTeamSectionOpacityBox{
    opacity: 0;
}
.ourTeamSectionOpacityContainer:hover .ourTeamSectionOpacityBox{
    opacity: 100;
    transition: .2s all ease-in-out;
}
@media screen and (max-width:600px) {
    .outTeamSectionImg{
        max-height: 280px;
    }
}