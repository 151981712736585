

@media screen and (max-width:1024px) {
    .greatHomeSectionImg {
        width: 350px;
        height: 350px;
    }
}

@media screen and (max-width:768px) {
    .greatHomeSectionImg {
        width: 300px;
        height: 300px;
    }
}

@media screen and (max-width:600px) {
    .greatHomeSectionImg {
        width: 250px;
        height: 250px;
    }
}

@media screen and (max-width:600px) {
    .greatHomeSectionImg {
        width: 225px;
        height: 225px;
    }
}