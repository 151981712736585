.detailsSectionImg{
    max-height: 500px;
}
@media screen and (max-width:1200px) {
    .detailsSectionImg{
        max-height: 475px;
        
    }
}
@media screen and (max-width:1024px) {
    .detailsSectionImg{
        max-height: 355px;
    }
}