

.contactOption1{
    top: 2px;
    right: 12px;
    z-index: -1;
    transition: ease-in-out;
    visibility:hidden;
   
}
.contactOption2{
    top: 3px;
    right: 10px;
    z-index: -1;
    transition: ease-in-out;
    visibility:hidden;
}
.contactOption3{
    bottom: 14px;
    left: 14px;
    z-index: -1;
    transition: ease-in-out;
    visibility:hidden;
}
.contactOptionsBox{
    z-index: 100 !important;
}
 .contactOption1{
    top: -3rem;
    right: 1.25rem;
    visibility:visible;
}
 .contactOption2{
    top: -1rem;
    right: 5rem;
    visibility:visible;
}
 .contactOption3{
    bottom: -0.75rem;
    left: -3.5rem;
    visibility:visible;
}