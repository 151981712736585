.landingNavbarLogo{
    
 
    height: 70px !important;
}
.landingNavbarDots{
    z-index: 2;
    position: absolute; 
    top: 20px;
    right: 20px;
    
}
.landingNavbarOpacityBack {
    // animation: example 0.2s linear alternate;
}

.landingNavbarLogo {
    object-fit: contain;
    object-position: center;
}

@keyframes example{
    from{opacity: 0;}
    to{opacity: 100;}
}

.landingNavbarOpacityItems{
    margin-top: auto !important;
}
@media screen and (max-width:1605px) {
    .landingNavbarOpacityItems{
        margin-top: 400px;
    }
}


@media screen and (max-width:880px) {
    .navbarlinksss,
    .navbarlinks {
        * {
            font-size: clamp(12px, 1.5vw, 14px);
        }
    }
}

@media screen and (max-width:600px) {
        .landingNavbarLogo{
            height: 30px !important;
        }
}
@media screen and (max-width:768px) {
    .landingNavbarContainer{
        position: sticky;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        clip-path: circle(25px at calc(100% - 45px) 45px);
        transition: all 0.3s ease-in-out;
    }
    .landingNavbarContainerNew{
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        clip-path: circle(75%);
        transition: all 0.3s ease-in-out;
    }
}

