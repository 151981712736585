.buildingBrickSectionImg{
    height: 500px;
}

@media screen and (max-width:1300px) {
    .buildingBrickSectionImg{
        max-height: 400px;
    }
}
@media screen and (max-width:1024px) {
    .buildingBrickSectionImg{
        max-height: 350px;
    }
}
@media screen and (max-width:768px) {
    .buildingBrickSectionImg{
        max-height: 300px;
    }
}
@media screen and (max-width:600px) {
    .buildingBrickSectionImg{
        max-height: 225px;
    }
}
@media screen and (max-width:450px) {
    .buildingBrickSectionImg{
        max-height: 175px;
    }
}